
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { BIcon, BIconChevronRight } from "bootstrap-vue";
import { Suggestion } from "~/types/components";

@Component({ components: { BIcon, BIconChevronRight } })
export default class SuggestionCard extends Vue {
  @Prop() card!: Suggestion;
  @Prop({ default: true }) isAsta: boolean;
  @Prop() componentClass!: string;
  public effect: boolean = false;

  public toggleHover(bool: boolean) {
    this.effect = bool;
  }

  public image(): string {
    if (this.isAsta) {
      return this.card.img;
    } else {
      if (this.card.img) {
        return this.card.size === "cardx2" ? this.card.img[1024] : this.card.img[512];
      }
      return "https://library.astagiudiziaria.com/tappi/tappo.png";
    }
  }
}
