import { render, staticRenderFns } from "./SuggestionList.vue?vue&type=template&id=0b2ffa41&scoped=true"
import script from "./SuggestionList.vue?vue&type=script&lang=ts"
export * from "./SuggestionList.vue?vue&type=script&lang=ts"
import style0 from "./SuggestionList.vue?vue&type=style&index=0&id=0b2ffa41&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b2ffa41",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeSuggestionsSuggestionCard: require('/usr/src/nuxt-app/components/home/suggestions/SuggestionCard.vue').default})
