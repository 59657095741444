
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { GenreCollection } from "~/types/Filters";
import { Suggestion } from "~/types/components";

@Component
export default class SuggestionList extends Vue {
  @Prop() items!: any[];
  public suggestions: Suggestion[] = [];

  mounted() {
    const collections = new GenreCollection();
    this.suggestions = this.items.map((item, k) => {
      return {
        size: k === 0 || k === 5 ? "cardx2" : "cardx1",
        title: item.titolo,
        description: item.contenuto,
        img: item.main_image,
        href: `/ricerca/${collections.getAlias(item.parent_description)}?filter%5Bgenre%5D%5B0%5D=${
          item.parent_description
        }&filter%5Bcategory%5D%5B0%5D=${item.categoria}`,
      };
    });
  }
}
